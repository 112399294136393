import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const DashboardStyles: ComponentStylesWithFns = {
  statsWrapper: {
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '1fr 1fr 1fr'],
    gridColumnGap: 24,
    rowGap: 4,
    height: 'calc(33vh - 60px)',
  },
  statsWrapper2: {
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '1fr .64fr .64fr .64fr'],
    gridColumnGap: 24,
    rowGap: 4,
    height: 'calc(33vh - 60px)',
  },
  statsContainer: {
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'clip',
    border: '0.5px solid',
    borderColor: 'white',
    height: '100%',
  },
  chartBox: {
    gridArea: 'Chart',
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 4,
    border: '0.5px solid',
    borderColor: 'white',
  },
};
