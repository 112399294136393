import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import LineChartStudioMonth from '../line-chart-custom/LineChartStudioMonth';
import TollOutlinedIcon from '@mui/icons-material/TollOutlined';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import BarChartStatsMonth from '../bar-chart-custom/BarChartStatsMonth';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import { DashboardStyles } from './Dashboards.Styles';
import { useStyles } from '../../theme/styles.helpers';
import { useTranslation } from 'react-i18next';
import { Utils, Orders } from '@hellohair/types';
import {
  calculateTotalEarnings,
  getRequestsStats,
  groupByCurrentAndPreviousMonth,
  groupByDayInMonthWithEmpty,
} from '../../helpers/requests';
import { toEuro } from '../../helpers/common';

type Props = {
  requests: Utils.DBItem<Orders.OrderRequest>[];
};

const ManagerDashboard = ({ requests }: Props) => {
  const { t } = useTranslation();
  const styles = useStyles(DashboardStyles, {});

  const requestsByMonths = groupByCurrentAndPreviousMonth(requests);
  const requestsByDaysInCurrentMonth = groupByDayInMonthWithEmpty(requestsByMonths.currentMonth);
  const currentMonthTotalEarnings = calculateTotalEarnings(requestsByMonths.currentMonth);
  const previousMonthTotalEarnings = calculateTotalEarnings(requestsByMonths.previousMonth);
  const stats = getRequestsStats(requestsByMonths.currentMonth);

  return (
    <>
      <Box sx={styles.statsWrapper}>
        <Box sx={styles.statsContainer}>
          <LineChartStudioMonth requestsByDays={requestsByDaysInCurrentMonth} />
        </Box>
        <Box sx={styles.statsContainer}>
          <SvgIcon sx={styles.statsIcon} component={TollOutlinedIcon}></SvgIcon>
          <Stack direction="column" spacing={1} justifyContent="center" alignItems="left">
            <Typography variant="h1">{toEuro.format(currentMonthTotalEarnings)}</Typography>
            <Typography variant="h5">{t('Balance')}</Typography>
          </Stack>
        </Box>
        <Box sx={styles.statsContainer}>
          <SvgIcon sx={styles.statsIcon} component={NextPlanOutlinedIcon}></SvgIcon>
          <Stack direction="column" spacing={1} justifyContent="center" alignItems="left">
            <Typography variant="h1">{toEuro.format(previousMonthTotalEarnings)}</Typography>
            <Typography variant="h5">{t('Previous month')}</Typography>
          </Stack>
        </Box>
      </Box>

      <Box sx={styles.statsWrapper2}>
        <Box sx={styles.statsContainer}>
          <BarChartStatsMonth requestsByDays={requestsByDaysInCurrentMonth} />
        </Box>
        <Box sx={styles.statsContainer}>
          <SvgIcon sx={styles.statsIcon} component={QuizOutlinedIcon}></SvgIcon>
          <Stack direction="column" spacing={1} justifyContent="center" alignItems="left">
            <Typography sx={{ alignContent: 'center' }} variant="h1">
              {stats.open}
            </Typography>
            <Typography variant="h5">{t('IDLE')}</Typography>
          </Stack>
        </Box>
        <Box sx={styles.statsContainer}>
          <SvgIcon sx={styles.statsIcon} component={TaskOutlinedIcon}></SvgIcon>
          <Stack direction="column" spacing={1} justifyContent="center" alignItems="left">
            <Typography variant="h1">{stats.allowed}</Typography>
            <Typography variant="h5">{t('ALLOWED')}</Typography>
          </Stack>
        </Box>
        <Box sx={styles.statsContainer}>
          <SvgIcon sx={styles.statsIcon} component={DisabledByDefaultOutlinedIcon}></SvgIcon>
          <Stack direction="column" spacing={1} justifyContent="center" alignItems="left">
            <Typography variant="h1">{stats.denied}</Typography>
            <Typography variant="h5">{t('DENIED')}</Typography>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default ManagerDashboard;
