import { CustomDataGrid } from '../data-grid/CustomDataGrid';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../theme/styles.helpers';
import { BillingStyles } from './Billing.Styles';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { GridRowId } from '@mui/x-data-grid';
import { DataContext } from '../../aws/DataProvider';
import { NEW_CLIENT_BONUS, getAllDoctorsFromStudio } from '../../helpers/requests';
import { toEuro } from '../../helpers/common';
import { listOrderRequestsForUser } from '../../aws/RequestsApi';
import { AuthContext } from '../../aws/AuthProvider';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

type Props = {
  selectedMonth: string;
  selectedStudioId: string;
};

type OrderRow = {
  orderId: string;
  customer: string;
  studio: string;
  orderDate: string;
  product: string;
  bonus: string;
};

const FirstOrderBonusExport = ({ selectedMonth, selectedStudioId }: Props) => {
  const { t } = useTranslation();
  const styles = useStyles(BillingStyles, {});
  const {
    studiosState: [studios],
    usersState: [users],
    customersState: [customers],
  } = useContext(DataContext);
  const { apiClient } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<OrderRow[]>([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    (async () => {
      if (!selectedStudioId) return;
      setLoading(true);

      const targetStudio = studios.find((studio) => studio.id === selectedStudioId);
      const studioName = targetStudio?.name || '';
      setTitle(studioName);
      const doctorsFromStudio = getAllDoctorsFromStudio(users, targetStudio || null).map((doctor) => doctor.sub);

      const selectedCustomers = customers.filter(
        (customer) => customer.doctorId && doctorsFromStudio.includes(customer.doctorId)
      );

      const mappedOrders = [];
      const selectedDate = new Date(selectedMonth);
      const selectedMonthNumber = selectedDate.getMonth();
      const selectedYear = selectedDate.getFullYear();

      for (const selectedCustomer of selectedCustomers) {
        const allOrdersForSelectedCustomer = await listOrderRequestsForUser(
          selectedCustomer.customerId,
          apiClient,
          'network-only'
        );
        const firstOrder = allOrdersForSelectedCustomer[0];
        if (!firstOrder) continue;
        const orderDate = new Date(firstOrder.createdAt);
        const orderMonth = orderDate.getMonth();
        const orderYear = orderDate.getFullYear();

        if (orderMonth !== selectedMonthNumber || orderYear !== selectedYear) continue;

        mappedOrders.push({
          // @ts-expect-error: TODO: fix this
          orderId: firstOrder.orderId,
          customer: selectedCustomer.firstName + ' ' + selectedCustomer.lastName,
          studio: studioName,
          orderDate: new Date(firstOrder.createdAt).toLocaleDateString('de-DE'),
          product: firstOrder.items.map((item) => item.description).join(', '),
          bonus: toEuro.format(parseFloat(NEW_CLIENT_BONUS)),
        });
      }

      setSelectedRows(mappedOrders.map((order) => order.orderId));
      setSelectedOrders(mappedOrders);
      setLoading(false);
    })();
  }, [selectedMonth, selectedStudioId]);

  const handleSelectionChange = (selection: GridRowSelectionModel) => {
    setSelectedRows(selection);
  };

  const totalSum = selectedRows.length * parseFloat(NEW_CLIENT_BONUS);

  const downloadPdf = async () => {
    const pdf = new jsPDF('landscape', 'px', 'a4'); // Portrait, A4 size
    const filename = 'Bonusabrechnung ' + selectedMonth + ' ' + title;

    const columns = [
      { header: 'Name', dataKey: 'customer' },
      { header: 'Praxis', dataKey: 'studio' },
      { header: 'Datum', dataKey: 'orderDate' },
      { header: 'Produkt', dataKey: 'product' },
      { header: 'Bonus', dataKey: 'bonus' },
    ];

    const body = selectedOrders.filter((row) => selectedRows.includes(row.orderId));
    body.push({
      orderId: '',
      customer: '',
      studio: '',
      orderDate: '',
      product: '',
      bonus: toEuro.format(totalSum),
    });

    autoTable(pdf, {
      columns,
      body,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 30, left: 10 }, // Decreased margins
      headStyles: {
        fillColor: [0, 0, 0], // Black background
        textColor: [255, 255, 255], // White text
        fontStyle: 'bold',
      },
      styles: { fontSize: 8 },
      didParseCell: (data) => {
        // Check if it's the last row
        if (data.row.index === body.length - 1) {
          data.cell.styles.fontStyle = 'bold';
        }
      },
    });

    const totalPagesToCalculate = pdf.internal.pages.length - 1;
    pdf.setFontSize(8);
    for (let i = 1; i <= totalPagesToCalculate; i++) {
      pdf.setPage(i);
      const pageSize = pdf.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

      // bottom left corner
      pdf.text(
        filename,
        10,
        pageHeight - 10 // Bottom of the page
      );

      // bottom right corner
      pdf.text(
        `Seite ${i} von ${totalPagesToCalculate}`,
        pageWidth - 50, // 20mm from the right edge
        pageHeight - 10 // 10mm from the bottom edge
      );
    }

    pdf.save(
      filename
        .split(' ')
        .filter((w) => w !== '-')
        .join('_')
        .toLowerCase() + '.pdf'
    );
  };

  return (
    <>
      <CustomDataGrid
        columns={[
          {
            field: 'customer',
            headerName: 'Name',
            flex: 1,
          },
          {
            field: 'studio',
            headerName: 'Praxis',
            flex: 1,
          },
          {
            field: 'orderDate',
            headerName: t('Date'),
            flex: 1,
          },
          {
            field: 'product',
            headerName: 'Produkt',
            flex: 1,
          },
          {
            field: 'bonus',
            headerName: 'Bonus (TBD)',
            flex: 1,
          },
        ]}
        rows={selectedOrders || []}
        getRowId={(row) => row.orderId}
        isRowSelectable={() => true}
        pagination
        loading={loading}
        autoPageSize
        sx={styles.row}
        ignoreDiacritics
        checkboxSelection
        disableRowSelectionOnClick
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={handleSelectionChange}
      />
      <Box paddingX={7} paddingY={4} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Button onClick={downloadPdf}>
          <Typography>{t('Download PDF')}</Typography>
        </Button>

        <Typography variant="h3">
          {t('Total')} {toEuro.format(totalSum)}
        </Typography>
      </Box>
    </>
  );
};

export default FirstOrderBonusExport;
