import { Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { GridRowParams } from '@mui/x-data-grid';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthState } from '../../aws/AuthProvider';
import { CustomDataGrid } from '../../components/data-grid/CustomDataGrid';
import { CustomModal } from '../../components/modal/CustomModal';
import { OrderDetails } from '../../components/modals/order-details/OrderDetails';
import { useStyles } from '../../theme/styles.helpers';
import { ROUTE } from '../routes';
import { OrderRequestsStyles } from './OrderRequests.Styles';
import { Orders } from '@hellohair/types';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DataContext } from '../../aws/DataProvider';
import { getRequestTableRow } from '../../helpers/requests';

interface RequestListRow {
  orderId: string;
  orderDate: Date;
  name: string;
  doctor: string;
  product: string;
  requestState: string;
  isFollowup: boolean;
}

const OrderRequests: FC = () => {
  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);
  const {
    ordersState: [requests],
    usersState: [users],
  } = useContext(DataContext);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { t } = useTranslation();
  const styles = useStyles(OrderRequestsStyles, {});
  const states = ['IDLE', 'ALLOWED', 'DENIED'];
  const [rows, setRows] = useState<RequestListRow[]>();
  const [requestId, setRequestId] = useState<string | undefined>(undefined);
  const [isFollowup, setIsFollowup] = useState<boolean>(false);
  const [freeTextSearch, setFreeTextSearch] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = React.useState<string[]>([states[0]]);

  useEffect(() => {
    const _rows = requests
      .map((r) => getRequestTableRow(r, users))
      .filter((row: RequestListRow) => selectedStatus.includes(row.requestState));
    setRows(_rows);
  }, [requests]);

  useEffect(() => {
    const _rows = requests
      .map((r) => getRequestTableRow(r, users))
      .filter((row) => selectedStatus.includes(row.requestState));
    setRows(_rows);
  }, [selectedStatus]);

  useEffect(() => {
    if (!freeTextSearch) {
      const _rows = requests
        .map((r) => getRequestTableRow(r, users))
        .filter((row) => selectedStatus.includes(row.requestState));
      setRows(_rows);
      return;
    }
    const _rows = requests
      .map((r) => getRequestTableRow(r, users))
      .filter((row) => {
        return Object.values(row).some((value) => {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(freeTextSearch.toLowerCase());
          }
          return false;
        });
      });
    setRows(_rows);
  }, [freeTextSearch]);

  const handleRowClicked = (params: GridRowParams) => {
    const { orderId, isFollowup: _isFollowup } = params.row as RequestListRow;
    setRequestId(orderId);
    setIsFollowup(_isFollowup);
    setIsEditModalOpen(true);
  };

  const handleSelectedStatusChange = (event: SelectChangeEvent<typeof selectedStatus>) => {
    const {
      target: { value },
    } = event;
    setSelectedStatus(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h3">{t('OrderRequests')}</Typography>
        <Box display={'flex'} gap={2}>
          <FormControl sx={{ width: 300 }} variant="outlined">
            <TextField
              label="Suchen"
              variant="outlined"
              value={freeTextSearch}
              InputLabelProps={{ sx: { textIndent: '10px', fontSize: '18px' } }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFreeTextSearch(event.target.value)}
            />
          </FormControl>

          <FormControl sx={{ width: 200 }}>
            <InputLabel
              id="multiple-checkbox-label"
              sx={{
                textIndent: '10px',
              }}
            >
              Status
            </InputLabel>
            <Select
              labelId="multiple-checkbox-label"
              id="multiple-checkbox"
              multiple
              value={selectedStatus}
              onChange={handleSelectedStatusChange}
              input={<OutlinedInput label="Status" />}
              renderValue={(selected) => selected.map((s) => t(s)).join(', ')}
            >
              {states.map((st) => (
                <MenuItem key={st} value={st}>
                  <Checkbox checked={selectedStatus.includes(st)} />
                  <ListItemText primary={t(st)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <CustomDataGrid
        columns={[
          {
            field: 'orderId',
            headerName: t('Request'),
            flex: 0.7,
          },
          { field: 'orderDate', headerName: t('Date'), type: 'date', flex: 1 },
          { field: 'name', headerName: t('Customer'), flex: 2 },
          { field: 'doctor', headerName: t('Doctor'), flex: 2 },
          { field: 'product', headerName: t('Product'), flex: 2 },
          {
            field: 'requestState',
            headerName: t('Status'),
            flex: 0.4,
            align: 'center',
            renderCell: ({ value }) => {
              const style = {
                width: '10px',
                height: '10px',
                borderRadius: '5px',
                backgroundColor:
                  value === Orders.OrderRequestState.IDLE
                    ? '#4881d7'
                    : value === Orders.OrderRequestState.ALLOWED
                      ? 'green'
                      : '#df1b41',
              };
              return <div style={style} />;
            },
          },
        ]}
        rows={rows || []}
        onRowClick={handleRowClicked}
        getRowId={(row) => row.orderId}
        isCellEditable={() => false}
        isRowSelectable={() => false}
        pagination
        autoPageSize
        sx={styles.row}
        initialState={{
          sorting: {
            sortModel: [{ field: 'orderDate', sort: 'desc' }],
          },
        }}
      />
      <CustomModal variant="large" isModalOpen={isEditModalOpen} onModalClose={() => setIsEditModalOpen(false)}>
        <OrderDetails onModalClose={() => setIsEditModalOpen(false)} requestId={requestId} isFollowup={isFollowup} />
      </CustomModal>
    </Box>
  );
};

export default OrderRequests;
