import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import { DashboardStyles } from './Dashboards.Styles';
import { useStyles } from '../../theme/styles.helpers';
import { useTranslation } from 'react-i18next';
import { Utils, Orders } from '@hellohair/types';
import BarChartStatsTotal from '../bar-chart-custom/BarChartStatsTotal';
import { getRequestsStats } from '../../helpers/requests';

type Props = {
  requests: Utils.DBItem<Orders.OrderRequest>[];
};

const CommonDashboard = ({ requests }: Props) => {
  const { t } = useTranslation();
  const styles = useStyles(DashboardStyles, {});
  const stats = getRequestsStats(requests);

  return (
    <>
      <Box sx={styles.statsWrapper}>
        <Box sx={styles.statsContainer}>
          <SvgIcon sx={styles.statsIcon} component={QuizOutlinedIcon}></SvgIcon>
          <Stack direction="column" spacing={1} justifyContent="center" alignItems="left">
            <Typography sx={{ alignContent: 'center' }} variant="h1">
              {stats.open}
            </Typography>
            <Typography variant="h5">{t('IDLE')}</Typography>
          </Stack>
        </Box>
        <Box sx={styles.statsContainer}>
          <SvgIcon sx={styles.statsIcon} component={TaskOutlinedIcon}></SvgIcon>
          <Stack direction="column" spacing={1} justifyContent="center" alignItems="left">
            <Typography variant="h1">{stats.allowed}</Typography>
            <Typography variant="h5">{t('ALLOWED')}</Typography>
          </Stack>
        </Box>
        <Box sx={styles.statsContainer}>
          <SvgIcon sx={styles.statsIcon} component={DisabledByDefaultOutlinedIcon}></SvgIcon>
          <Stack direction="column" spacing={1} justifyContent="center" alignItems="left">
            <Typography variant="h1">{stats.denied}</Typography>
            <Typography variant="h5">{t('DENIED')}</Typography>
          </Stack>
        </Box>
      </Box>

      <Box sx={styles.chartBox}>
        <BarChartStatsTotal requests={requests} />
      </Box>
    </>
  );
};

export default CommonDashboard;
