import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthState } from '../../aws/AuthProvider';
import { useStyles } from '../../theme/styles.helpers';
import { ROUTE } from '../routes';
import { BillingStyles } from './Billing.Styles';
import { DataContext } from '../../aws/DataProvider';
import ListItemText from '@mui/material/ListItemText';
import { getAllDoctorsFromStudio, getCurrentMonth } from '../../helpers/requests';
import PrescriptionFeeExport from '../../components/billing/PrescriptionFeeExport';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FirstOrderBonusExport from '../../components/billing/FirstOrderBonusExport';

const Billing: FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(BillingStyles, {});
  const {
    studiosState: [studios],
    usersState: [users],
  } = useContext(DataContext);
  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);
  const [selectedTab, setTab] = useState('one');
  const [selectedDoctorId, setSelectedDoctorId] = useState<string>('');
  const [selectedStudioId, setSelectedStudioId] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<string>(getCurrentMonth());

  const doctorsWithStudios = studios.reduce(
    (acc, studio) => {
      acc.push({
        value: 'S#' + studio.id || '',
        label: studio.name,
      });

      const doctors = getAllDoctorsFromStudio(users, studio);

      doctors.forEach((doctor) => {
        acc.push({
          value: 'D#' + doctor.sub || '',
          label: doctor.firstName + ' ' + doctor.lastName,
        });
      });

      return acc;
    },
    [] as {
      value: string;
      label: string;
    }[]
  );

  const doctorsWithoutStudio = users
    .filter((user) => user.groups.includes('doctor') && !user.studio && !studios.find((s) => s.managerId === user.sub))
    .map((d) => ({
      value: 'D#' + d.sub || '',
      label: d.firstName + ' ' + d.lastName,
    }));

  const docStudioSelectItems = doctorsWithoutStudio.concat(doctorsWithStudios);

  const handleDoctorChange = (event: SelectChangeEvent) => {
    const [type, value] = (event.target.value as string).split('#');

    if (type === 'S') {
      setSelectedStudioId(value);
      setSelectedDoctorId('');
      return;
    }
    setSelectedDoctorId(value);
    setSelectedStudioId('');
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={selectedTab}
            onChange={(_event: React.SyntheticEvent, newValue: string) => {
              setTab(newValue);
              setSelectedDoctorId('');
              setSelectedStudioId('');
            }}
          >
            <Tab value="one" label={t('Billing')} />
            <Tab value="two" label={'Bonusabrechnung'} />
          </Tabs>
        </Box>
        <Box display={'flex'} gap={2}>
          <FormControl sx={{ width: 300 }}>
            <InputLabel
              id="select-studio-label"
              sx={{
                textIndent: '10px',
              }}
            >
              {selectedTab === 'one' ? 'Praxis/Arzt' : 'Praxis'}
            </InputLabel>
            <Select
              labelId="select-studio-label"
              id="select-studio"
              value={selectedStudioId || selectedDoctorId}
              onChange={handleDoctorChange}
              input={<OutlinedInput label={selectedTab === 'one' ? 'Praxis/Arzt' : 'Praxis'} />}
              renderValue={(selected) => docStudioSelectItems.find((d) => d.value.includes(selected))?.label}
            >
              {docStudioSelectItems.map(({ value, label }) => {
                const isDoctor = value.startsWith('D#');
                return (
                  <MenuItem
                    key={value}
                    value={value}
                    disabled={selectedTab === 'two' && isDoctor}
                    sx={{
                      paddingLeft: isDoctor ? 5 : 3,
                      '& span': { fontWeight: isDoctor ? 'normal' : 'bold' },
                    }}
                  >
                    <ListItemText primary={label} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl sx={{ width: 220 }} variant="outlined">
            <TextField
              type={'month'}
              label="Monat"
              variant="outlined"
              InputLabelProps={{ sx: { textIndent: '10px', fontSize: '18px' } }}
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            />
          </FormControl>
        </Box>
      </Box>

      {selectedTab === 'one' && (
        <PrescriptionFeeExport
          selectedDoctorId={selectedDoctorId}
          selectedStudioId={selectedStudioId}
          selectedMonth={selectedMonth}
        />
      )}
      {selectedTab === 'two' && (
        <FirstOrderBonusExport selectedStudioId={selectedStudioId} selectedMonth={selectedMonth} />
      )}
    </Box>
  );
};

export default Billing;
