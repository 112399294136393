import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../aws/DataProvider';
import { useStyles } from '../../theme/styles.helpers';
import { DashboardStyles } from './Dashboard2.Styles';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthContext, AuthState } from '../../aws/AuthProvider';
import { ROUTE } from '../routes';
import { Box, Link, SvgIcon, Typography } from '@mui/material';
import { RequestsShortList } from '../../components/requests-short-list/RequestsShortList';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import ManagerDashboard from '../../components/dashboards/manager-dashboard';
import CommonDashboard from '../../components/dashboards/common-dashboard';

const Dashboard2: FC = () => {
  const { t } = useTranslation();
  const {
    ordersState: [requests],
    studiosState: [studios],
  } = useContext(DataContext);
  const { userAttributes } = useContext(AuthContext);
  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);
  const navigate = useNavigate();
  const styles = useStyles(DashboardStyles, {});

  const managingStudio = studios.find((studio) => studio.managerId === userAttributes?.id);
  const isSupportUser = userAttributes?.roles?.includes('support');

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Typography variant="h3">{t('Dashboard')}</Typography>
      </Box>
      {studios.length > 0 && (
        <Box sx={styles.container}>
          {!managingStudio && <CommonDashboard requests={requests} />}
          {managingStudio && <ManagerDashboard requests={requests} />}

          {!isSupportUser && (
            <Box sx={styles.listBox}>
              <Typography variant="h5" marginBottom={2}>
                {t('LastRequests')}
              </Typography>

              <RequestsShortList requests={(requests || []).slice(0, 3)} />

              <Link
                onClick={() => navigate(ROUTE.REQUESTS)}
                component="button"
                sx={styles.link}
                variant="h6"
                color="inherit"
                fontSize={16}
              >
                {t('AllRequests')}
                <SvgIcon sx={styles.arrowIcon} component={ArrowForwardIcon}></SvgIcon>
              </Link>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Dashboard2;
