import AWSAppSyncClient from 'aws-appsync';
import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthProvider';
import { NormalizedCacheObject } from './aws.config';
import { listTemplates } from './TemplatesApi';
import { listOrderRequests, listStudios } from './RequestsApi';
import { listStatistics } from './StatisticsApi';
import { listUserGroups, listUsers } from './UsersApi';
import { listConsultations } from './ConsultationApi';
import { listCustomers } from './CustomersApi';
import { Users, Utils, Orders, Templates, Stats, Consultations, Studio, Customers } from '@hellohair/types';
import { getAllDoctorsFromStudio, getFirstDateOfMonthsAgo } from '../helpers/requests';

interface DataContextSchema {
  // Forms
  formsState: [
    Utils.DBItem<Templates.SurveyTemplate>[],
    Dispatch<SetStateAction<Utils.DBItem<Templates.SurveyTemplate>[]>>,
  ];
  formDetailsState: [
    Utils.DBItem<Templates.SurveyTemplate> | undefined,
    Dispatch<SetStateAction<Utils.DBItem<Templates.SurveyTemplate> | undefined>>,
  ];
  // Customer Requests
  ordersState: [Utils.DBItem<Orders.OrderRequest>[], Dispatch<SetStateAction<Utils.DBItem<Orders.OrderRequest>[]>>];
  orderDetailsState: [
    Utils.DBItem<Orders.OrderRequestDetails> | undefined,
    Dispatch<SetStateAction<Utils.DBItem<Orders.OrderRequestDetails> | undefined>>,
  ];
  // Statistics
  statisticsState: [Stats.StatsData[], Dispatch<SetStateAction<Stats.StatsData[]>>];
  // Backend users
  usersState: [Users.BackendUser[], Dispatch<SetStateAction<Users.BackendUser[]>>];
  userDetailsState: [Users.BackendUser | undefined, Dispatch<SetStateAction<Users.BackendUser | undefined>>];
  userGroupsState: [Users.UserGroup[], Dispatch<SetStateAction<Users.UserGroup[]>>];
  consultationsState: [
    Utils.DBItem<Consultations.CalendarEvent>[],
    Dispatch<SetStateAction<Utils.DBItem<Consultations.CalendarEvent>[]>>,
  ];
  customersState: [Customers.ShopCustomer[], Dispatch<SetStateAction<Customers.ShopCustomer[]>>];
  prescriptionState: [Users.PrescriptionState | null, Dispatch<SetStateAction<Users.PrescriptionState | null>>];
  subscriptionsState: [Users.Subscription[] | null, Dispatch<SetStateAction<Users.Subscription[] | null>>];
  studiosState: [Studio[], Dispatch<SetStateAction<Studio[]>>];
}

const dummyFunction = () => {
  console.error('Should not have happened');
};

export const DataContext = createContext<DataContextSchema>({
  formsState: [[], dummyFunction],
  formDetailsState: [undefined, dummyFunction],
  ordersState: [[], dummyFunction],
  orderDetailsState: [undefined, dummyFunction],
  statisticsState: [[], dummyFunction],
  usersState: [[], dummyFunction],
  userDetailsState: [undefined, dummyFunction],
  userGroupsState: [[], dummyFunction],
  consultationsState: [[], dummyFunction],
  customersState: [[], dummyFunction],
  prescriptionState: [null, dummyFunction],
  subscriptionsState: [null, dummyFunction],
  studiosState: [[], dummyFunction],
});

interface Props {
  children: React.ReactNode;
}

const DataProvider: FC<Props> = ({ children }) => {
  const formsState = useState<Utils.DBItem<Templates.SurveyTemplate>[]>([]);
  const [, setForms] = formsState;

  const formDetailsState = useState<Utils.DBItem<Templates.SurveyTemplate> | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formDetails, setFormDetails] = formDetailsState;

  const ordersState = useState<Utils.DBItem<Orders.OrderRequest>[]>([]);
  const [, setOrders] = ordersState;

  const consultationsState = useState<Utils.DBItem<Consultations.CalendarEvent>[]>([]);
  const [, setConsultations] = consultationsState;

  const orderDetailsState = useState<Utils.DBItem<Orders.OrderRequestDetails> | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderDetails, setOrderDetails] = orderDetailsState;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const statisticsState = useState<any>({});
  const [, setStatistics] = statisticsState;

  const usersState = useState<Users.BackendUser[]>([]);
  const [users, setUsers] = usersState;

  const customersState = useState<Customers.ShopCustomer[]>([]);
  const [, setCustomers] = customersState;

  const userDetailsState = useState<Users.BackendUser | undefined>();
  //const [userDetails, setUserDetails] = userDetailsState;

  const userGroupsState = useState<Users.UserGroup[]>([]);
  const [, setUserGroups] = userGroupsState;

  const prescriptionState = useState<Users.PrescriptionState | null>(null);
  // const [, setPrescriptionState] = prescriptionState;

  const subscriptionsState = useState<Users.Subscription[] | null>(null);

  const studiosState = useState<Studio[]>([]);
  const [studios, setStudios] = studiosState;

  const { apiClient } = useContext(AuthContext);
  const { userAttributes } = useContext(AuthContext);

  const queryAndSetForms = async (_apiClient: AWSAppSyncClient<NormalizedCacheObject>) => {
    const _forms = await listTemplates(_apiClient);
    setForms(_forms);
  };

  const queryAndSetConsultations = async (_apiClient: AWSAppSyncClient<NormalizedCacheObject>) => {
    const _consultations = await listConsultations(_apiClient);
    setConsultations(_consultations);
  };

  const queryAndSetStatistics = async (_apiClient: AWSAppSyncClient<NormalizedCacheObject>) => {
    const _statitics = await listStatistics(2023, _apiClient);
    setStatistics(_statitics);
  };

  const queryAndSetUsers = async (_apiClient: AWSAppSyncClient<NormalizedCacheObject>) => {
    const _users = await listUsers(_apiClient);
    setUsers(_users);
  };

  const queryAndSetCustomers = async (_apiClient: AWSAppSyncClient<NormalizedCacheObject>) => {
    const _customers = await listCustomers(_apiClient);
    setCustomers(_customers);
  };

  const queryAndSetUserGroups = async (_apiClient: AWSAppSyncClient<NormalizedCacheObject>) => {
    const _userGroups = await listUserGroups(_apiClient);
    setUserGroups(_userGroups);
  };

  const queryAndSetStudios = async (_apiClient: AWSAppSyncClient<NormalizedCacheObject>) => {
    const _studios = await listStudios(_apiClient);
    setStudios(_studios);
  };

  useEffect(() => {
    if (apiClient && userAttributes && studios.length > 0 && users.length > 0) {
      // TODO: provide default doctor id from environment variable
      const isProduction = window.location.hostname === 'doc.hellohair.com';
      const defaultDoctorId = isProduction
        ? '0394e8a2-a031-7035-cd05-38c3cfa9ade5' // info@ifue-haartransplantation.de (prod)
        : 'f314d822-f011-705b-6b37-4cc79561dc71'; // andreas@hellohair.com (dev)

      const managingStudio = studios.find((studio) => studio.managerId === userAttributes?.id);
      let monthsAgo = getFirstDateOfMonthsAgo(2);

      let doctorIds: string[] | undefined = undefined;

      if (userAttributes?.id === defaultDoctorId) {
        doctorIds = [];
        console.warn('return all requests without assigned doctor: ');
      } else if (userAttributes?.roles?.includes('admin')) {
        console.warn('return all requests for admin: ');
      } else if (studios.find((studio) => studio.managerId === userAttributes?.id)) {
        monthsAgo = getFirstDateOfMonthsAgo(1);
        doctorIds = getAllDoctorsFromStudio(users, managingStudio || null).map((d) => d.sub);
        console.warn('return all requests for manager: ', userAttributes?.id);
      } else if (userAttributes?.roles?.includes('doctor')) {
        doctorIds = [userAttributes.id];
        console.warn('return all requests for doctor: ');
      }

      listOrderRequests({
        apiClient,
        fetchPolicy: 'network-only',
        from: monthsAgo.toString(),
        doctorIds,
      }).then((data) => {
        console.warn('requests: ', data);
        setOrders(data);
      });
    }
  }, [apiClient, userAttributes, studios, users]);

  useEffect(() => {
    if (!apiClient) {
      setForms([]);
      setConsultations([]);
      setStatistics({});
      setUsers([]);
      setCustomers([]);
      setUserGroups([]);
      setStudios([]);
      return;
    }

    queryAndSetForms(apiClient);
    queryAndSetConsultations(apiClient);
    queryAndSetStatistics(apiClient);
    queryAndSetUsers(apiClient);
    queryAndSetUserGroups(apiClient);
    queryAndSetCustomers(apiClient);
    queryAndSetStudios(apiClient);
  }, [apiClient]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DataContext.Provider
      value={{
        formsState: formsState,
        formDetailsState: formDetailsState,
        ordersState,
        consultationsState,
        orderDetailsState,
        statisticsState,
        usersState,
        userDetailsState,
        userGroupsState,
        customersState,
        prescriptionState,
        subscriptionsState,
        studiosState,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
