import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Utils, Orders } from '@hellohair/types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      stacked: true, // Stack datasets on the x-axis
      grid: {
        display: false, // Hide x-axis grid
      },
    },
    y: {
      display: false, // Hide y-axis
      stacked: true, // Stack datasets on the y-axis
      ticks: {
        display: false, // Hide x-axis labels
      },
      grid: {
        display: false, // Hide x-axis grid
      },
    },
  },
};

const BarChartStatsMonth = ({
  requestsByDays,
}: {
  requestsByDays: Record<string, Utils.DBItem<Orders.OrderRequest>[]>;
}) => {
  const { t } = useTranslation();

  const data = {
    labels: Object.keys(requestsByDays),
    datasets: [
      {
        label: t('ALLOWED'),
        data: Object.values(requestsByDays).map((day) => day.filter((req) => req.orderState === 'ALLOWED').length),
        backgroundColor: 'green',
        border: 10,
      },
      {
        label: t('DENIED'),
        data: Object.values(requestsByDays).map((day) => day.filter((req) => req.orderState === 'DENIED').length),
        backgroundColor: 'rgba(223, 27, 65, 1)',
        border: 10,
      },
      {
        label: t('IDLE'),
        data: Object.values(requestsByDays).map((day) => day.filter((req) => req.orderState === 'IDLE').length),
        backgroundColor: 'rgb(72, 129, 215)',
        border: 10,
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

export default BarChartStatsMonth;
