import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const DashboardStyles: ComponentStylesWithFns = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    overscrollBehavior: 'none',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '112px',
    marginX: 'auto',
    width: '90%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: 4,
    marginX: 'auto',
    width: '90%',
    paddingBottom: '24px',
  },
  listBox: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '230px',
  },
  link: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 4,
  },
  arrowIcon: {
    fontSize: '16px',
  },
  statsIcon: {},
};
