import React, { useContext, useMemo, useState } from 'react';
import { useStyles } from '../../theme/styles.helpers';
import { RequestsShortListStyles } from './RequestsShortListStyles';
import { useTranslation } from 'react-i18next';
import { Orders, Utils } from '@hellohair/types';
import { CustomModal } from '../modal/CustomModal';
import { OrderDetails } from '../modals/order-details/OrderDetails';
import { Box } from '@mui/material';
import { CustomDataGrid } from '../data-grid/CustomDataGrid';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { getRequestTableRow } from '../../helpers/requests';
import { DataContext } from '../../aws/DataProvider';

interface RequestListProps {
  requests: Utils.DBItem<Orders.OrderRequest>[];
}

interface RequestListRow {
  orderId: string;
  requestDate: string;
  customerName: string;
  productName: string;
  requestType: string;
  requestState: string;
  isFollowup: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RequestsShortList: React.FC<RequestListProps> = ({ requests }) => {
  const styles = useStyles(RequestsShortListStyles, {});
  const {
    usersState: [users],
  } = useContext(DataContext);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { t } = useTranslation();

  const [requestId, setRequestId] = useState<string | undefined>(undefined);
  const [isFollowup, setIsFollowup] = useState<boolean>(false);

  const rows = useMemo(() => requests.map((r) => getRequestTableRow(r, users)), [requests]);

  const handleRowClicked = (params: GridRowParams) => {
    const { orderId, isFollowup: _isFollowup } = params.row as RequestListRow;
    setRequestId(orderId);
    setIsFollowup(_isFollowup);
    setIsEditModalOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'orderId',
      headerName: t('Request'),
      flex: 0.7,
    },
    { field: 'orderDate', headerName: t('Date'), type: 'date', flex: 1 },
    { field: 'name', headerName: t('Customer'), flex: 2 },
    { field: 'doctor', headerName: t('Doctor'), flex: 2 },
    { field: 'product', headerName: t('Product'), flex: 2 },
    {
      field: 'requestState',
      headerName: t('Status'),
      flex: 0.4,
      align: 'center',
      renderCell: ({ value }) => {
        const style = {
          width: '10px',
          height: '10px',
          borderRadius: '5px',
          backgroundColor:
            value === Orders.OrderRequestState.IDLE
              ? '#4881d7'
              : value === Orders.OrderRequestState.ALLOWED
                ? 'green'
                : '#df1b41',
        };
        return <div style={style} />;
      },
    },
  ];

  return (
    <Box sx={styles.container}>
      <CustomDataGrid
        columnHeaderHeight={0}
        columns={columns}
        rows={rows || []}
        onRowClick={handleRowClicked}
        getRowId={(row) => row.orderId}
        isCellEditable={() => false}
        isRowSelectable={() => false}
        pagination
        initialState={{}}
        sx={styles.row}
      />

      <CustomModal variant="large" isModalOpen={isEditModalOpen} onModalClose={() => setIsEditModalOpen(false)}>
        <OrderDetails onModalClose={() => setIsEditModalOpen(false)} requestId={requestId} isFollowup={isFollowup} />
      </CustomModal>
    </Box>
  );
};
