import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';
import { HistoryListStyles } from './HistoryList.styles';
import { useStyles } from '../../../theme/styles.helpers';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { CheckCircle, HighlightOff, HourglassTop } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Orders, Utils } from '@hellohair/types';

interface HistoryListProps {
  historyData: Utils.DBItem<Orders.OrderRequest>[];
  onItemClick: (itemId: string) => void;
}

const HistoryList: FC<HistoryListProps> = ({ historyData, onItemClick }) => {
  const styles = useStyles(HistoryListStyles, {});
  const { t } = useTranslation();

  return (
    <Box sx={styles.root} className="HistoryList-root">
      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          maxHeight: '100%',
          overflowY: 'auto',
          borderRadius: 4,
          marginTop: 2,
          backgroundColor: 'text.primary',
          padding: '20px',
        }}
      >
        <Box>
          <Typography sx={styles.header} variant="h4" color="white">
            {t('History')}
          </Typography>

          <MenuList disablePadding>
            {historyData.map((data) => (
              <MenuItem key={data.requestId} onClick={() => onItemClick(data.requestId)} sx={styles.item}>
                <ListItemIcon>
                  {data.orderState === Orders.OrderRequestState.IDLE && <HourglassTop color="warning" />}
                  {data.orderState === Orders.OrderRequestState.ALLOWED && <CheckCircle color="success" />}
                  {data.orderState === Orders.OrderRequestState.DENIED && <HighlightOff color="error" />}
                </ListItemIcon>
                <ListItemText
                  primary={`${t('Request')} ${new Date(data.createdAt).toLocaleDateString()}`}
                  primaryTypographyProps={{ color: 'white' }}
                />
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </Paper>
    </Box>
  );
};

export default HistoryList;
