import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const BillingStyles: ComponentStylesWithFns = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    overscrollBehavior: 'none',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '112px',
    marginX: 'auto',
    width: '90%',
  },
  row: {
    '& .MuiDataGrid-row': {
      fontWeight: 'normal',
      fontSize: '15px',
      cursor: 'pointer',
      borderTop: '0.5px solid',
      borderColor: 'grey.200',
      color: 'text.primary',
      '& .MuiDataGrid-cell': {
        border: 'none',
        outline: 'none',
        fontSize: '16px',
      },
    },
  },
};
