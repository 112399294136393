import { CustomDataGrid } from '../data-grid/CustomDataGrid';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { BillingStyles } from './Billing.Styles';
import { useStyles } from '../../theme/styles.helpers';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { GridRowId } from '@mui/x-data-grid';
import { DOCTOR_FEE_1, DOCTOR_FEE_2, getAllDoctorsFromStudio } from '../../helpers/requests';
import { toEuro } from '../../helpers/common';
import { listOrderRequests } from '../../aws/RequestsApi';
import { Orders, Utils } from '@hellohair/types';
import { DataContext } from '../../aws/DataProvider';
import { AuthContext } from '../../aws/AuthProvider';

type Props = {
  selectedDoctorId: string;
  selectedMonth: string;
  selectedStudioId: string;
};

function getFirstAndLastDate(dateString: string) {
  // Split the provided date string to extract year and month
  const [year, month] = dateString.split('-').map(Number);
  return {
    firstDate: new Date(year, month - 1, 1).getTime(),
    lastDate: new Date(year, month, 0).getTime(),
  };
}

const PrescriptionFeeExport = ({ selectedDoctorId, selectedMonth, selectedStudioId }: Props) => {
  const {
    studiosState: [studios],
    usersState: [users],
    customersState: [customers],
  } = useContext(DataContext);
  const { t } = useTranslation();
  const { apiClient } = useContext(AuthContext);
  const styles = useStyles(BillingStyles, {});
  const [requests, setRequests] = useState<Utils.DBItem<Orders.OrderRequest>[]>([]);
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);

  useEffect(() => {
    if (!selectedDoctorId && !selectedStudioId) return;

    let doctorsToFilter = [selectedDoctorId];

    // if default doctor is selected, show requests without assigned doctor, empty array filter
    if (selectedDoctorId === '0394e8a2-a031-7035-cd05-38c3cfa9ade5') {
      doctorsToFilter = [];
    }

    if (selectedStudioId) {
      const targetStudio = studios.find((studio) => studio.id === selectedStudioId);
      doctorsToFilter = getAllDoctorsFromStudio(users, targetStudio || null).map((doctor) => doctor.sub);
    }

    const { firstDate, lastDate } = getFirstAndLastDate(selectedMonth);
    listOrderRequests({
      apiClient,
      fetchPolicy: 'network-only',
      from: firstDate.toString(),
      to: lastDate.toString(),
      doctorIds: doctorsToFilter,
    }).then((data) => {
      const allowedOrDeniedRequests = data.filter(
        (request) =>
          request.orderState === Orders.OrderRequestState.ALLOWED ||
          request.orderState === Orders.OrderRequestState.DENIED
      );

      setRequests(allowedOrDeniedRequests);
      setSelectedRows(allowedOrDeniedRequests.map((request) => request.requestId) as GridRowId[]);
    });
  }, [selectedDoctorId, selectedMonth, selectedStudioId]);

  const doctors = users.filter((user) => user.groups.includes('doctor'));

  const totals = useMemo(() => {
    return requests.map((request) => {
      const isAllowed = request.orderState === Orders.OrderRequestState.ALLOWED;
      const isExcluded = !selectedRows.includes(request.requestId);
      const customer = request.customer || {};
      const factor = parseFloat(request.assignedDoctor?.factor || '1.0');
      const factor2 = isAllowed ? parseFloat(request.assignedDoctor?.factor2 || '1.0') : 0;
      const fee = parseFloat(request.assignedDoctor?.fee || DOCTOR_FEE_1);
      const fee2 = isAllowed ? parseFloat(request.assignedDoctor?.fee2 || DOCTOR_FEE_2) : 0;
      const sum = fee * factor;
      const sum2 = fee2 * factor2;
      const total = sum + sum2;

      const dob =
        customer.birthDate || customers.find((c) => c.customerId === customer.customerId)?.birthDate || '01.01.1970';

      return {
        requestId: request.requestId,
        name: customer.customerName,
        dob: new Date(dob).toLocaleDateString('de-DE'),
        fee,
        factor,
        sum,
        fee2,
        factor2,
        sum2,
        total: isExcluded ? 0 : total,
      };
    });
  }, [requests, selectedRows]);

  const rows = totals.map((row) => ({
    ...row,
    total: toEuro.format(row.total),
    sum: toEuro.format(row.sum),
    sum2: row.sum2 ? toEuro.format(row.sum2) : '-',
    fee: toEuro.format(row.fee),
    fee2: row.fee2 ? toEuro.format(row.fee2) : '-',
    factor2: row.factor2 ? row.factor2 : '-',
  }));

  const totalSum = totals.reduce((acc, row) => acc + row.total, 0);

  const handleSelectionChange = (selection: GridRowSelectionModel) => {
    setSelectedRows(selection);
  };

  const printTitle = () => {
    const month = new Date(selectedMonth).toLocaleDateString('de-DE', {
      month: 'long',
      year: 'numeric',
    } as Intl.DateTimeFormatOptions);

    if (selectedStudioId) {
      return month + ' - ' + studios.find((d) => d.id === selectedStudioId)?.name;
    }

    if (selectedDoctorId) {
      const doctor = doctors.find((d) => d.sub === selectedDoctorId);
      const fullName = doctor?.firstName + ' ' + doctor?.lastName;

      return month + ' - ' + fullName;
    }
    return month;
  };
  const downloadPdf = async () => {
    const pdf = new jsPDF('landscape', 'px', 'a4'); // Portrait, A4 size
    const title = printTitle();

    const columns = [
      { header: 'Name', dataKey: 'name' },
      { header: 'Geburtsdatum', dataKey: 'dob' },
      { header: 'Diagnose', dataKey: 'diagnose' },
      { header: 'Anzahl (der erfolgten Leistungen)', dataKey: 'cnt' },
      { header: 'Leistung/ Begründung; GoÄ Nr, 1', dataKey: 'description1' },
      { header: 'Einfachsatz', dataKey: 'p1' },
      { header: 'Faktor', dataKey: 'f1' },
      { header: 'Vergütungsbetrag', dataKey: 's1' },
      { header: 'Leistung/ Begründung; GoÄ Nr, 2', dataKey: 'description2' },
      { header: 'Einfachsatz', dataKey: 'p2' },
      { header: 'Faktor', dataKey: 'f2' },
      { header: 'Vergütungsbetrag', dataKey: 's2' },
      { header: 'Gesamt', dataKey: 's' },
    ];

    const body = rows
      .filter((row) => selectedRows.includes(row.requestId))
      .map((row) => {
        return {
          name: row.name,
          dob: row.dob,
          diagnose: 'Androgenetische Alopezie',
          cnt: '1',
          description1: 'Telemedizinische Beratung der Nutzer durch einen Arzt',
          p1: row.fee,
          f1: row.factor.toString(),
          s1: row.sum,
          description2: 'Übermittlung von Rezepten',
          p2: row.fee2,
          f2: row.factor2,
          s2: row.sum2,
          s: row.total,
        };
      });

    body.push({
      name: '',
      dob: '',
      diagnose: '',
      cnt: '',
      description1: '',
      p1: '',
      f1: '',
      s1: '',
      description2: '',
      p2: '',
      f2: '',
      s2: '',
      s: toEuro.format(totalSum),
    });

    autoTable(pdf, {
      columns,
      body,
      theme: 'grid',
      margin: { top: 10, right: 10, bottom: 30, left: 10 }, // Decreased margins
      headStyles: {
        fillColor: [0, 0, 0], // Black background
        textColor: [255, 255, 255], // White text
        fontStyle: 'bold',
      },
      styles: { fontSize: 8 },
      didParseCell: (data) => {
        // Check if it's the last row
        if (data.row.index === body.length - 1) {
          data.cell.styles.fontStyle = 'bold';
        }
      },
    });

    const totalPagesToCalculate = pdf.internal.pages.length - 1;
    pdf.setFontSize(8);
    for (let i = 1; i <= totalPagesToCalculate; i++) {
      pdf.setPage(i);
      const pageSize = pdf.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

      // bottom left corner
      pdf.text(
        title,
        10,
        pageHeight - 10 // Bottom of the page
      );

      // bottom right corner
      pdf.text(
        `Seite ${i} von ${totalPagesToCalculate}`,
        pageWidth - 50, // 20mm from the right edge
        pageHeight - 10 // 10mm from the bottom edge
      );
    }

    pdf.save(
      title
        .split(' ')
        .filter((w) => w !== '-')
        .join('_')
        .toLowerCase() + '.pdf'
    );
  };

  return (
    <>
      <CustomDataGrid
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
          },
          {
            field: 'dob',
            headerName: 'Geburtsdatum',
            flex: 1,
          },
          {
            field: 'fee',
            headerName: 'GoÄ 1 - Preis',
            flex: 1,
          },
          {
            field: 'factor',
            headerName: 'GoÄ 1 - Faktor',
            flex: 1,
          },
          {
            field: 'sum',
            headerName: 'GoÄ 1 - Summe',
            flex: 1,
          },
          {
            field: 'fee2',
            headerName: 'GoÄ 2 - Preis',
            flex: 1,
          },
          {
            field: 'factor2',
            headerName: 'GoÄ 2 - Faktor',
            flex: 1,
          },
          {
            field: 'sum2',
            headerName: 'GoÄ 2 - Summe',
            flex: 1,
          },
          {
            field: 'total',
            headerName: t('Total'),
            flex: 1,
          },
        ]}
        rows={rows || []}
        getRowId={(row) => row.requestId}
        isRowSelectable={() => true}
        pagination
        autoPageSize
        sx={styles.row}
        ignoreDiacritics
        checkboxSelection
        disableRowSelectionOnClick
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={handleSelectionChange}
        initialState={{
          sorting: {
            sortModel: [{ field: 'dateCreated', sort: 'desc' }],
          },
        }}
      />
      <Box paddingX={7} paddingY={4} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Button onClick={downloadPdf}>
          <Typography>{t('Download PDF')}</Typography>
        </Button>

        <Typography variant="h3">
          {t('Total')} {toEuro.format(totalSum)}
        </Typography>
      </Box>
    </>
  );
};

export default PrescriptionFeeExport;
