import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Orders, Utils } from '@hellohair/types';
import { useEffect, useState } from 'react';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const CHART_MIN_HEIGHT = 200;
const CHART_OFFSET = 715;

const BarChartStatsTotal = ({ requests }: { requests: Utils.DBItem<Orders.OrderRequest>[] }) => {
  const { t } = useTranslation();
  const [chartHeight, setChartHeight] = useState(0);

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const newHeight = windowHeight - CHART_OFFSET < CHART_MIN_HEIGHT ? CHART_MIN_HEIGHT : windowHeight - CHART_OFFSET;
    setChartHeight(newHeight);
  }, []);

  if (!chartHeight) return null;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };

  const data = requests.reduce(
    (a, c) => {
      const currentMonth = new Date(c.createdAt).toString().split(' ')[1];

      a.total[currentMonth] = a.total[currentMonth] ? a.total[currentMonth] + 1 : 1;
      if (c.orderState === Orders.OrderRequestState.ALLOWED) {
        a.allowed[currentMonth] = a.allowed[currentMonth] ? a.allowed[currentMonth] + 1 : 1;
      }
      if (c.orderState === Orders.OrderRequestState.DENIED) {
        a.denied[currentMonth] = a.denied[currentMonth] ? a.denied[currentMonth] + 1 : 1;
      }
      if (c.orderState === Orders.OrderRequestState.FAILED) {
        a.failed[currentMonth] = a.failed[currentMonth] ? a.failed[currentMonth] + 1 : 1;
      }
      if (c.orderState === Orders.OrderRequestState.IDLE) {
        a.idle[currentMonth] = a.idle[currentMonth] ? a.idle[currentMonth] + 1 : 1;
      }
      return a;
    },
    {
      total: {} as Record<string, number>,
      idle: {} as Record<string, number>,
      allowed: {} as Record<string, number>,
      denied: {} as Record<string, number>,
      failed: {} as Record<string, number>,
    }
  );

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const date = new Date();
  const labels = [];

  for (let i = 0; i < 3; i++) {
    let currentMonth = (date.getMonth() - i + 12) % 12;
    if (currentMonth === 12) currentMonth = 0;
    labels.push(months[currentMonth]);
  }

  const barData = {
    labels: labels.reverse(),
    datasets: [
      {
        label: 'Total',
        data: labels.map((l) => data.total[l] ?? 0),
        backgroundColor: 'black',
      },
      {
        label: t('ALLOWED'),
        data: labels.map((l) => data.allowed[l] ?? 0),
        backgroundColor: 'green',
      },
      {
        label: t('DENIED'),
        data: labels.map((l) => data.denied[l] ?? 0),
        backgroundColor: 'rgba(223, 27, 65, 1)',
      },
      {
        label: t('IDLE'),
        data: labels.map((l) => data.idle[l] ?? 0),
        backgroundColor: 'rgb(72, 129, 215)',
      },
    ],
  };

  return <Bar options={options} data={barData} height={chartHeight + 'px'} />;
};

export default BarChartStatsTotal;
