const SidebarStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderRight: '0.5px solid',
    borderColor: 'primary.light',

    '.sidebar-appLinksList': {
      flexGrow: 1,
      height: '77vh',
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        width: '6px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#aeaeae',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#888',
      },
    },
    '.logo': {
      marginY: '36px',
      marginX: 5,
      width: '150px',
    },
  },
};

export default SidebarStyles;
