import { GraphQLResult } from '@aws-amplify/api';
import FetchPolicy from 'apollo-client/core/watchQueryOptions';
import AWSAppSyncClient from 'aws-appsync';
import { NormalizedCacheObject } from './aws.config';
import {
  ASSIGN_DOCTOR_ID_TO_CUSTOMER,
  CREATE_REQUEST,
  CREATE_STUDIO,
  DELETE_REQUEST,
  GENERATE_REZEPT,
  PAUSE_SUBSCRIPTION,
  SEND_COMMENT,
  SEND_COMMENT_FOLLOWUP,
  SEND_NOTIFICATION,
  SEND_NOTIFICATION_FOLLOWUP,
  UPDATE_PRESCRIPTION_STATE,
  UPDATE_STUDIO,
} from './graphql/mutations';
import {
  EVALUATE_REQUEST_CODE,
  GET_REQUEST,
  LIST_REQUESTS,
  LIST_ORDER_HISTORY_FOR_USER,
  GET_ORDER_HISTORY_DETAILS,
  GET_RETURNS_FOR_CUSTOMER,
  GET_FOLLOWUP,
  GET_SUBSCRIPTIONS,
  GET_CUSTOMER_PRESCRIPTION_STATE,
  LIST_STUDIOS,
  GET_STUDIO,
} from './graphql/queries';
import { Utils, Orders, Studio } from '@hellohair/types';

// export type OrderRequestDetails = Extension<Orders.OrderRequest, { transactions: OrderTransaction[] }>;

// type OrderTransaction = {
//   id: string;
//   createdAt: number;
//   name: string;
//   description: string;
//   eventType: string;
// };

export async function listOrderRequests({
  apiClient,
  fetchPolicy,
  from,
  to,
  doctorIds,
  customerId,
}: {
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>;
  fetchPolicy: FetchPolicy.FetchPolicy;
  from?: string;
  to?: string;
  doctorIds?: string[];
  customerId?: string;
}): Promise<Utils.DBItem<Orders.OrderRequest>[]> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const _requests = await apiClient.query<{ listRequests: Utils.DBItem<Orders.OrderRequest>[] }>({
      query: LIST_REQUESTS,
      variables: { from, to, doctorIds, customerId },
      fetchPolicy: fetchPolicy,
    });
    return _requests?.data?.listRequests.sort((a, b) => b.createdAt - a.createdAt);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[RequestsApi] listOrderRequests errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function listOrderRequestsForUser(
  customerId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<Utils.DBItem<Orders.UserOrder>[]> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const _requests = await apiClient.query<{
      listOrderHistoryForUser: Utils.DBItem<Orders.UserOrder>[];
    }>({
      query: LIST_ORDER_HISTORY_FOR_USER,
      variables: { customerId },
      fetchPolicy: fetchPolicy,
    });
    return _requests?.data?.listOrderHistoryForUser.sort((a, b) => b.createdAt - a.createdAt);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[RequestsApi] listOrderRequestsForUser errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function getOrderHistoryDetails(
  customerId: string,
  orderId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<Orders.UserOrderHistory> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const _requests = await apiClient.query<{
      getOrderHistoryDetails: Orders.UserOrderHistory;
    }>({
      query: GET_ORDER_HISTORY_DETAILS,
      variables: { customerId, orderId },
      fetchPolicy: fetchPolicy,
    });
    return _requests?.data?.getOrderHistoryDetails;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[RequestsApi] getOrderHistoryDetails errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function getReturnsForCustomer(
  customerId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<Orders.UserReturn[]> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const _requests = await apiClient.query<{ getReturnsForCustomer: Orders.UserReturn[] }>({
      query: GET_RETURNS_FOR_CUSTOMER,
      variables: { customerId },
      fetchPolicy: fetchPolicy,
    });
    return _requests?.data?.getReturnsForCustomer;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function getOrderRequest(
  requestId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<Utils.DBItem<Orders.OrderRequestDetails>> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const _request = await apiClient.query<{ getRequest: Utils.DBItem<Orders.OrderRequestDetails> }>({
      query: GET_REQUEST,
      variables: { requestId },
      fetchPolicy: fetchPolicy,
    });
    console.log('[RequestsApi] getRequest ', _request);
    return _request?.data?.getRequest;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[RequestsApi] getRequest errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function getOrderFollowupRequest(
  requestId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<Utils.DBItem<Orders.OrderFollowupRequestDetails>> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const _request = await apiClient.query<{ getFollowup: Utils.DBItem<Orders.OrderFollowupRequestDetails> }>({
      query: GET_FOLLOWUP,
      variables: { requestId },
      fetchPolicy: fetchPolicy,
    });
    console.log('[RequestsApi] getFollowup ', _request);
    return _request?.data?.getFollowup;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[RequestsApi] getFollowup errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function getStripeSubscriptions(
  stripeId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<[object]> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const _request = await apiClient.query<{ getSubscriptions: [object] }>({
      query: GET_SUBSCRIPTIONS,
      variables: { stripeId },
      fetchPolicy: fetchPolicy,
    });
    console.log('[RequestsApi] getSubscriptions ', _request);
    return _request?.data?.getSubscriptions;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[RequestsApi] getSubscriptions errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function pauseSubscription(
  subscriptionId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<boolean> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: PAUSE_SUBSCRIPTION,
      variables: { subscriptionId },
    })) as GraphQLResult<{ pauseSubscription: boolean }>;
    return result?.data?.pauseSubscription || false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }

    throw error;
  }
}

export async function getCustomerPrescriptionState(
  customerId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<string> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const _request = await apiClient.query<{ getCustomerPrescriptionState: string }>({
      query: GET_CUSTOMER_PRESCRIPTION_STATE,
      variables: { customerId },
      fetchPolicy: fetchPolicy,
    });
    console.log('[RequestsApi] getCustomerPrescriptionState ', _request);
    return _request?.data?.getCustomerPrescriptionState.toString();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[RequestsApi] getCustomerPrescriptionState errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function updateCustomerPrescriptionState(
  customerId: string,
  newState: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: UPDATE_PRESCRIPTION_STATE,
      variables: { customerId, newState },
    })) as GraphQLResult<{ updatePrescriptionState: string }>;
    console.log('[RequestApi] - updateCustomerPrescriptionState - result:', customerId, result);

    return result?.data?.updatePrescriptionState || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }

    throw error;
  }
}

export async function assignDoctorIdToCustomer(
  customerId: string,
  doctorId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: ASSIGN_DOCTOR_ID_TO_CUSTOMER,
      variables: { customerId, doctorId },
    })) as GraphQLResult<{ assignDoctorIdToCustomer: string }>;
    return result?.data?.assignDoctorIdToCustomer || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }

    throw error;
  }
}

export async function handleRequest(
  requestId: string,
  action: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<boolean> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }
  try {
    const result = (await apiClient.mutate({
      mutation: SEND_NOTIFICATION,
      variables: { requestId, action },
    })) as GraphQLResult<{ updateRequest: boolean }>;
    console.log('[RequestApi] - handleRequest - result:', requestId, result);

    return result?.data?.updateRequest || false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }

    throw error;
  }
}

export async function handleFollowup(
  requestId: string,
  action: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<boolean> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }
  try {
    const result = (await apiClient.mutate({
      mutation: SEND_NOTIFICATION_FOLLOWUP,
      variables: { requestId, action },
    })) as GraphQLResult<{ updateFollowup: boolean }>;
    console.log('[RequestApi] - handleFollowup - result:', requestId, result);

    return result?.data?.updateFollowup || false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }

    throw error;
  }
}

export async function handleComment(
  requestId: string,
  comment: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<boolean> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }
  try {
    const result = (await apiClient.mutate({
      mutation: SEND_COMMENT,
      variables: { requestId, action: 'COMMENT', comment },
    })) as GraphQLResult<{ updateRequest: boolean }>;
    console.log('[RequestApi] - handleComment - result:', requestId, result);

    return result?.data?.updateRequest || false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function handleCommentFollowup(
  requestId: string,
  comment: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<boolean> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }
  try {
    const result = (await apiClient.mutate({
      mutation: SEND_COMMENT_FOLLOWUP,
      variables: { requestId, action: 'COMMENT', comment },
    })) as GraphQLResult<{ updateFollowup: boolean }>;
    console.log('[RequestApi] - handleComment - result:', requestId, result);

    return result?.data?.updateFollowup || false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function createRequest(
  requestKey: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  console.log('[RequestsApi] - createRequest: ', requestKey);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: CREATE_REQUEST,
      variables: { requestKey },
    })) as GraphQLResult<{ addRequest: string }>;
    console.log('[RequestApi] - requestInput - result:', requestKey, result);

    return result?.data?.addRequest || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function deleteRequest(
  requestId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  console.log('[RequestsApi] - deleteRequest: ', requestId);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: DELETE_REQUEST,
      variables: { id: requestId },
    })) as GraphQLResult<{ deleteRequest: '{}' }>;
    console.log('[RequestApi] - deleteRequest - result:', requestId, result);

    return result?.data?.deleteRequest || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function evaluateRequest(
  code: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<string> {
  console.log('[RequestsApi] - evaluateRequest: ', code);

  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  console.log('#### debug query');

  try {
    const result = (await apiClient.query({
      query: EVALUATE_REQUEST_CODE,
      variables: { code },
      fetchPolicy: fetchPolicy,
    })) as GraphQLResult<{ evaluateCode: string }>;

    console.log('[RequestApi] - requestInput - result:', code, result);

    return result?.data?.evaluateCode || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

//FIXME: KHOFMAN Subscriptions
export async function generateRezept(
  productId: string,
  requestId: string,
  customerId: string,
  isSubscription: boolean,
  isFollowup: boolean,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
) {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: GENERATE_REZEPT,
      variables: { data: { productId, customerId, requestId, isSubscription, isFollowup } },
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
    })) as GraphQLResult<{ generateRezept: any }>;
    console.log('[RequestsApi] generateRezept ', result);
    return result?.data.generateRezept;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[RequestsApi] generateRezept errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function createStudio(
  studio: Studio,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: CREATE_STUDIO,
      variables: { studio },
    })) as GraphQLResult<{ createStudio: string }>;
    console.log('[RequestApi] - createStudio - result:', result.data?.createStudio);

    return result?.data?.createStudio;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}

export async function listStudios(apiClient?: AWSAppSyncClient<NormalizedCacheObject>): Promise<Studio[]> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.query({
      query: LIST_STUDIOS,
    })) as GraphQLResult<{ listStudios: Studio[] }>;
    console.log('[RequestApi] - listStudios - result:', result.data?.listStudios);

    return result?.data?.listStudios;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}

export async function updateStudio(
  studio: Studio,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: UPDATE_STUDIO,
      variables: { studio },
    })) as GraphQLResult<{ updateStudio: string }>;
    console.log('[RequestApi] - updateStudio - result:', result.data?.updateStudio);

    return result?.data?.updateStudio;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}

export async function getStudio(
  id: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<Studio | null> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.query({
      query: GET_STUDIO,
      variables: { id },
    })) as GraphQLResult<{ getStudio: Studio }>;
    console.log('[RequestApi] - getStudio - result:', result.data?.getStudio);

    return result?.data?.getStudio;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}
