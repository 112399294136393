import { Box, Link, List } from '@mui/material';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import { AuthContext } from '../../aws/AuthProvider';
import { ROUTE } from '../../pages/routes';
import SidebarListItem, { SidebarListItemProps } from './sidebar-list-item/SidebarListItem';
import styles from './Sidebar.styles';
import { DataContext } from '../../aws/DataProvider';

const Sidebar: FC = () => {
  const { t, i18n } = useTranslation();
  const {
    studiosState: [studios],
  } = useContext(DataContext);
  const { userAttributes } = useContext(AuthContext);

  const location = useLocation();
  const [activeLink, setActiveLink] = useState<string>();

  useEffect(() => {
    if (location?.pathname) {
      setActiveLink(location.pathname);
    }
  }, [location]);

  const isActive = (path?: string) => {
    if (activeLink === '/') {
      return activeLink === path;
    }
    return path && activeLink ? path.includes(activeLink) : false;
  };

  const links: SidebarListItemProps[] = [
    { path: ROUTE.DASHBOARD, label: t('Dashboard'), roles: ['support', 'doctor', 'admin'] },
    { path: ROUTE.ANALYTICS, label: t('Analytics'), roles: ['admin'] },
    { path: ROUTE.FORMS, label: t('Forms'), roles: ['support', 'admin'] },
    { path: ROUTE.REQUESTS, label: t('Requests'), roles: ['doctor', 'admin'] },
    { path: ROUTE.PATIENTS, label: t('Patients'), roles: ['support', 'admin'] },
    { path: ROUTE.CONSULTATIONS, label: t('Consultations'), roles: ['doctor', 'admin'] },
    { path: ROUTE.USERS, label: t('Users'), roles: ['admin'], enabledForStudioManager: false },
    { path: ROUTE.STUDIOS, label: t('Partner'), roles: ['admin'], enabledForStudioManager: false },
    { path: ROUTE.BILLING, label: t('Billing'), roles: ['admin'], enabledForStudioManager: false },
    { path: ROUTE.PROFILE, label: t('Profile'), roles: ['support', 'doctor', 'admin'] },
    { path: ROUTE.SETTINGS, label: t('Settings'), roles: ['support', 'doctor', 'admin'] },
  ];

  const visibleLinks = useMemo(() => {
    return links.filter(({ roles, enabledForStudioManager }) => {
      const isStudioManager =
        userAttributes?.roles.includes('doctor') && !!studios.find((studio) => studio.managerId === userAttributes?.id);
      // if user is a studio manager and the link is for studio managers as well, return true
      if (isStudioManager && enabledForStudioManager) return true;

      const intersect = roles.filter((value) => userAttributes?.roles.includes(value));
      return !(roles.length > 0 && intersect.length === 0);
    });
  }, [userAttributes, i18n?.language, studios]); //eslint-disable-line react-hooks/exhaustive-deps

  // const actions: SidebarListItemProps[] = [{ action: handleLogout, label: 'Logout' }];

  return (
    <Box className="sidebar-root" sx={styles.root} hidden={false}>
      <Link href={ROUTE.DASHBOARD}>
        <Box component="img" className="logo" src={Logo} alt="Logo" />
      </Link>

      <List className="sidebar-appLinksList">
        {visibleLinks.map((props, index) => (
          <SidebarListItem {...props} selected={isActive(props.path)} key={`sidebar-list-item-app-${index}`} />
        ))}
      </List>
    </Box>
  );
};

export default Sidebar;
